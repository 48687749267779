<template>
  <div class="relative overflow-hidden" v-if="modelValue" v-bind="$attrs">
    <div class="blur-xl">
      <slot></slot>
    </div>
    <div
      class="cursor-pointer transition-all bg-neutral-500/60 group text-lg absolute inset-0 flex flex-col items-center justify-center"
      @click.prevent.stop="emit('update:modelValue', false)"
    >
      <div
        class="bg-neutral-100/80 dark:bg-neutral-800/80 transition-all px-4 py-1 rounded-full group-hover:bg-neutral-100 group-hover:dark:bg-neutral-800 font-medium tracking-wider uppercase"
      >
        Spoiler
      </div>
    </div>
  </div>
  <slot v-else v-bind="$attrs"></slot>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", v: boolean): void;
}>();
</script>

<style scoped lang="postcss"></style>
